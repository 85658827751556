import "./modules/loadMore.js";
import resourceItem from "../transformations/resourceItem.hbs";
import Cookies from 'js-cookie';

var $toteList = $("#resourceTotes");
loadTotes();

function loadTotes () {
    $toteList.loadMoreData({
      template: resourceItem,
      btnLoad: "#loadMoreBtn",
      firstLoad: 16,
      loadNum: 8,
      eventBefore: beforeLoadFn,
      eventAfter: function () {
        Foundation.reInit("equalizer");
      }
    });
  }

  function beforeLoadFn (data) {
    var newData = [];
  
    //sort by date or alphabetical
    $.each(data, function (index) {
      //check if user is artisanal
      if (Cookies.get('user_farmer_type') != "artisanal" && this.type.toLowerCase() == "artisanal") {
        return;
      }
  
      newData.push(this);
    });
    
    return newData;
  }
  
