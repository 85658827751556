import EventEmitter2 from 'eventemitter2';
import Cookies from 'js-cookie';

if (!window.EVT) {
  window.EVT = new EventEmitter2();
}

export { updateURLParameter, getUrlParameter, goToAnchor, waitForEvent, throttle, setFrenchText,  parallaxScroll, parallaxScrollTop, checkLoggedIn };

/* public function goToAnchor() */
let goToAnchor = ($target) => {
  // use an offset when there is sticky nav
  var headerOffset = $(".site-header").height() + 20;
  if ($target.length) {
    $("html, body").animate({
      scrollTop: $target.attr("id") === "top" ? 0 : $target.offset().top - headerOffset
    }, 900, () => {
      let x = window.scrollX, y = window.scrollY;
      $target[0].focus();
      if ($target.is(":focus")) {
        window.scrollTo(x, y);
        return false;
      } else {
        $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
        $target[0].focus(); // Set focus again
        window.scrollTo(x, y);
      }
    });
  }
}

/* public function waitForEvent() to run widget code when in Kentico Admin */
let waitForEvent = (eventName, callbackFn) => {
  if ($("body").attr("data-kentico") === "true") {
    window.EVT.on(eventName, callbackFn);
  }
  else {
    callbackFn();
  }
}

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
};

function updateURLParameter(param, paramVal) {
  var newAdditionalURL = "";
  var tempArray = window.location.href.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i=0; i<tempArray.length; i++) {
      if (tempArray[i].split('=')[0] != param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var rows_txt = temp + "" + param + "=" + paramVal;
  var changedURL = baseURL + "?" + newAdditionalURL + rows_txt;
  window.history.pushState({ path: changedURL }, '', changedURL);
}

function findWord(word, str) {
  return RegExp(word).test(str)
}

/* public function throttle() to improve performance of resize/scroll listeners */
let throttle = (func, wait, immediate) => {
  var timeout;
  return () => {
    var context = this,
      args = arguments,
      later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      },
      callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

let setFrenchText = () => {
  var $locale = $("html").attr("lang");
  
  if($(".story-tote__readmore-toggle").length){
    if($locale == "en-CA"){
      
      $(".story-tote__readmore-toggle").text("Read more");
    } else{
      //french
      $(".story-tote__readmore-toggle").text("Read more");
    }
  }
}

let parallaxScrollTop = (bg, speed) => {
  
	var scrolledY = $(window).scrollTop();

	bg.css('background-position','center -'+((scrolledY*speed))+'px');
}

let parallaxScroll = (bg,speed) => {
	var scrolledY = $(window).scrollTop();
  var bgHeight = bg.outerHeight();
	bg.css('background-position','0 -'+((scrolledY*speed) - (bgHeight / 2))+'px');
  //console.log((scrolledY*speed) - (bgHeight / 2))
}

let checkLoggedIn = () => {
  if(Cookies.get('user_farmer_type') == "quota" || Cookies.get('user_farmer_type') == "artisanal"){
    $("body").addClass("loggedin");
    $("body").addClass("artisanal");
  }
}

/* jQuery plugin functions */
// check if element is anywhere within viewport
$.fn.isInView = function () {
  var $this = $(this),
    $window = $(window),
    elementTop = $this.offset().top,
    elementBottom = elementTop + $this.outerHeight(),
    viewportTop = $window.scrollTop(),
    viewportBottom = viewportTop + $window.height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
}
